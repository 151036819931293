import Vue from 'vue'

import { usePersonStore } from '@/stores/person'
import { useEventStore } from '@/stores/event'

/**
 *
 * @param {*[]} values
 */
function joinFilter(values, joiner = ', ') {
  if (Array.isArray(values)) {
    return values.join(joiner)
  }
  return values
}


/**
 * Map a organisation short code or a list of codes to
 * a string of human readable names
 *
 * @param {string | string[]} val
 */
function mapOrgFilter(val) {
  const store = useEventStore()
  function mapSingleOrg(org) {
    if(store.meta.organisations) {
      const obj = store.meta.organisations.find((elem) => elem.code === org)
      if (obj !== undefined) {
        return obj.name
      }
    }
    return '??'
  }

  if(Array.isArray(val)) {
    return val.map(mapSingleOrg)
  } else {
    return mapSingleOrg(val)
  }
}

/**
 * Map a event kind shortcode to a human readable string.
 *
 * @param {string} val
 */
function mapKindFilter(val) {
  const store = useEventStore()
  if(store.meta.kinds) {
    const obj = store.meta.kinds.find((elem) => elem.value === val)
    if (obj !== undefined) {
      return obj.text
    }
  }
  return '??'
}

/**
 * Get the name of a person given be the persons id.
 *
 * @param {int} id
 * @returns {string} name
 */
function mapIDtoPerson(id) {
  const p = usePersonStore().getById(id)
  return p && p.name
}


function mapRole(role) {
  const ROLES = {
    admin: 'Administrator',
    manager: 'Tourenchef',
    guide: 'Leiter',
    emergency: 'Notfallstab',
  }

  return ROLES[role] || role
}

function formatAddress(person) {
  if(!person) {
    return ''
  }
  const address = person.address ? person.address.trim() : ''
  const city = `${person.zip_code} ${person.city}`.trim()
  return address && city ? `${address}, ${city}` : address + city
}

Vue.filter('join', joinFilter)
Vue.filter('mapOrg', mapOrgFilter)
Vue.filter('mapKind', mapKindFilter)
Vue.filter('mapIDtoPerson', mapIDtoPerson)
Vue.filter('mapRole', mapRole)
Vue.filter('formatAddress', formatAddress)
