import { loadView } from './helpers'

export default {
  path: '/admin',
  component: loadView('admin/Main'),
  meta: {
    auth: true,
    roles: ['admin'],
  },
  children: [
    {
      name: 'AdminDashboard',
      path: '',
      component: loadView('admin/Dashboard'),
    },
    {
      name: 'TouringGroups',
      path: 'touring-groups',
      component: loadView('admin/TouringGroups'),
    },
    {
      name: 'EventTypes',
      path: 'event-types',
      component: loadView('admin/EventTypes'),
    },
    {
      name: 'AdminTools',
      path: 'tools',
      component: loadView('admin/Tools'),
    },
  ],
}
